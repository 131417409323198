import React from 'react';
import Layout from '../components/Layout';
import { ReactComponent as NotFoundIllustation } from '../../static/images/illustrations/page-not-found.svg';


class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div style={{textAlign: 'center'}}>
          <h1>Not Found</h1>
          <p>You just hit a page that doesn&#39;t exist...</p>
          <img src={require('../../static/images/illustrations/page-not-found.svg').default} alt='mySvgImage' />
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
